import React, {useEffect, useRef, useState} from 'react';
import QRCodeStyling, {CornerDotType, DotType, FileExtension, Gradient} from "qr-code-styling";
import QRBorderPlugin, {ExtensionOptions} from "qr-border-plugin";
import {
    Box,
    Button,
    Card,
    Cell,
    CustomModalLayout,
    FieldSet,
    Heading,
    Layout,
    Modal,
    TextButton, Tooltip
} from "@wix/design-system";
import {CornerSquareType, ShapeType} from "qr-code-styling/lib/types";
import DropdownButton from "../button/DropdownButton";
import ColorSelect, {ColorType} from "./ColorSelect";
import QrCodeBorderSelect from "./QrCodeBorderSelect";
import {
    ChevronDownLargeSmall,
    ChevronRightLargeSmall, Link,
    Revert
} from '@wix/wix-ui-icons-common';
import QrCodeLogoSelector, {QrCodeLogoType} from "./QrCodeLogoSelector";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import {getQrCode, updateQrCode} from "../../services/qr-code-client";
import {useSearchParams} from "react-router-dom";
import {ToastContainer, toast, Bounce} from "react-toastify";
import QrCodePatternsToggle from "./toggle/QrCodePatternsToggle";
import QrCodeCornersToggle from "./toggle/QrCodeCornersToggle";
import QrCodeShapeToggle from "./toggle/QrCodeShapeToggle";
import {QrCodeResponse} from "../../models/models";

const qrCodeContainerStyle = {
    border: '0 0 0 4px rgb(56, 153, 237)',
    borderRadius: "10%",
    boxShadow: '0 4px 20px rgb(170, 219, 252)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const accordionItemPanelStyle = {
    width: '100%',
    margin: '0 auto',
    paddingBottom: "16px",
    paddingLeft: "5px",
    paddingRight: "5px",
    animation: "fadein 0.35s ease-in"
};

const addKeyframes = () => {
    const styleSheet = document.styleSheets[0];
    const keyframes =
        `@keyframes fadein {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }`;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
};

interface CustomQrGeneratorProps {
    qrCodeId: number,
    shortLinkId: number,
    shortUrl: string,
    shortLinkTitle: string,
    onClose: () => void
}

const CustomQrGenerator: React.FC<CustomQrGeneratorProps> = (
    {
        qrCodeId,
        shortLinkId,
        shortUrl,
        shortLinkTitle,
        onClose
    }) => {

  const qrRef = useRef<HTMLDivElement>(null);

  // QR-code && border styles
  const [qrCode, setQrCode] = useState<QRCodeStyling>(new QRCodeStyling());
  const [borderStyle, setBorderStyle] = useState<ExtensionOptions>();

  // QR-code pattern style parameters
  const [qrCodePattern, setQrCodePattern] = useState<DotType>();
  const [cornerPattern, setCornerPattern] = useState<string>("");
  const [qrShape, setQrShape] = useState<ShapeType>();

  // Colors parameters
  const [qrColorType, setQrColorType] = useState<ColorType>();
  const [backgroundColorType, setBackgroundColorType] = useState<ColorType>();
  const [qrCodeSingleColor, setQrCodeSingleColor] = useState<string>("");
  const [backgroundSingleColor, setBackgroundSingleColor] = useState<string>("");
  const [qrCodeGradient, setQrCodeGradient] = useState<string[]>([]);
  const [backgroundGradient, setBackgroundGradient] = useState<string[]>([]);
  const [qrCodeGradientRotation, setQrCodeGradientRotation] = useState<number>(2);
  const [backgroundGradientRotation, setBackgroundGradientRotation] = useState<number>(2);

  // QR-code logo
  const [logoType, setLogoType] = useState<QrCodeLogoType>();
  const [logoBase64, setLogoBase64] = useState<string | undefined>();
  const [customLogoFileName, setCustomLogoFileName] = useState<string | undefined>(undefined);

  // Accordion
  const [expanded, setExpanded] = useState<string[]>(['style-accordion']);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
      addKeyframes();
      getQrCode(qrCodeId, searchParams)
          .then(res => initQrCodeState(res.data))
  }, [qrCodeId, searchParams]);

  const initQrCodeState = (qrCodeResponse: QrCodeResponse) => {
      if (qrCodeResponse.qrCodeStyle) {
          const { qrCodeStyle, borderStyle, metaData } = qrCodeResponse;

          setQrCodePattern(qrCodeStyle.dotsOptions.type);
          setCornerPattern(qrCodeStyle.cornersDotOptions && qrCodeStyle.cornersSquareOptions
              ? qrCodeStyle.cornersDotOptions.type as string + "." + qrCodeStyle.cornersSquareOptions.type
              : "square.square"
          );
          setQrShape(qrCodeStyle.shape);

          setQrColorType(qrCodeStyle.dotsOptions.gradient
              ? qrCodeStyle.dotsOptions.gradient.type
              : "single-color")
          ;
          setBackgroundColorType(qrCodeStyle.backgroundOptions.gradient
              ? qrCodeStyle.backgroundOptions.gradient.type
              : "single-color"
          );

          setQrCodeSingleColor(qrCodeStyle.dotsOptions.color
              ? qrCodeStyle.dotsOptions.color
              : "#000000"
          );
          setBackgroundSingleColor(qrCodeStyle.backgroundOptions.color
              ? qrCodeStyle.backgroundOptions.color
              : "#FFFFFF"
          );

          setQrCodeGradient(qrCodeStyle.dotsOptions.gradient
              ? [ qrCodeStyle.dotsOptions.gradient.colorStops[0].color, qrCodeStyle.dotsOptions.gradient.colorStops[10].color ]
              : [ "#000000", "#000000" ]
          );
          setBackgroundGradient(qrCodeStyle.backgroundOptions.gradient
              ? [ qrCodeStyle.backgroundOptions.gradient.colorStops[0].color, qrCodeStyle.backgroundOptions.gradient.colorStops[10].color ]
              : [ "#FFFFFF", "#FFFFFF" ]
          );

          setQrCodeGradientRotation(qrCodeStyle.dotsOptions.gradient
              ? qrCodeStyle.dotsOptions.gradient.rotation as number
              : 2
          );
          setBackgroundGradientRotation(qrCodeStyle.backgroundOptions.gradient
              ? qrCodeStyle.backgroundOptions.gradient.rotation as number
              : 2
          );

          setLogoType(metaData.logoType);
          setLogoBase64(qrCodeStyle.image);
          setCustomLogoFileName(metaData.customLogoFileName);

          const qrInstance = new QRCodeStyling(qrCodeStyle);
          qrInstance.deleteExtension();

          if (borderStyle) {
              qrInstance.applyExtension(QRBorderPlugin(borderStyle));
          }
          setBorderStyle(borderStyle);

          if (qrRef.current) {
              qrRef.current.innerHTML = '';
              qrInstance.append(qrRef.current);
              setQrCode(() => qrInstance)
          }
      }
  }

  const getAccordionItemButtonStyle = (uuid: string) => ({
      border: '2px solid rgb(149, 201, 246)',
      borderRadius: '10px',
      transition: 'background-color 0.3s ease',
      backgroundColor: hoveredItem === uuid ? 'rgba(149, 201, 246, 0.3)' : 'transparent',
      boxShadow: hoveredItem === uuid ? '0 0 10px rgb(170, 219, 252)' : 'none',
  });

  const isItemExpanded = (uuid: string) => expanded.includes(uuid);

  const handleAccordionChange = (expandedItems: string[]) => {
      setExpanded(expandedItems);
  };

  const updateQrPattern = (qrCodePattern: DotType) => {
     setQrCodePattern(qrCodePattern);
     qrCode.update({
         dotsOptions: {
             ...qrCode._options.dotsOptions, // Keep existing options
             type: qrCodePattern,            // Update pattern type
         },
     });
  };

  const updateCornersPattern = (cornerType: string) => {
      setCornerPattern(cornerType);
      const parsedCornerTypes = cornerType.split(".");
      const dotType = parsedCornerTypes[0] as CornerDotType;
      const squareType = parsedCornerTypes[1] as CornerSquareType;

      qrCode.update({
          cornersDotOptions: {
              type: dotType
          },
          cornersSquareOptions: {
              type: squareType
          }
      });
  };

  const updateQrShape = (qrShape: ShapeType) => {
      setQrShape(qrShape);

      qrCode.update({
          shape: qrShape
      });

      qrCode.update({
          backgroundOptions: {
              ...qrCode._options.backgroundOptions, // Keep existing options
              round: borderStyle ? (qrShape === "square" ? 0.2 : 1) : 0,
          }
      });
  };

  const updateDotsOptionsColor = (color: string) => {
      setQrCodeSingleColor(color);
      qrCode.update({
          dotsOptions: {
              ...qrCode._options.dotsOptions, // Keep existing options
              color: color,              // Update color
              gradient: undefined,          // Remove gradient
          },
      });
  };

  const updateBackgroundOptionsColor = (color: string) => {
      setBackgroundSingleColor(color);
      qrCode.update({
          backgroundOptions: {
              ...qrCode._options.backgroundOptions, // Keep existing options
              color: color,
              gradient: undefined,            // Override with new options
              round: qrShape === "square" ? 0.2 : 1,
          },
      });
  };

  const updateDotsGradient = (gradient: Gradient) => {
      qrCode.update({
          dotsOptions: {
              ...qrCode._options.dotsOptions, // Keep existing options
              gradient: gradient,         // Update gradient
              color: undefined,              // Remove color
          },
      });
  };

  const updateBackgroundGradient = (gradient: Gradient) => {
      qrCode.update({
          backgroundOptions: {
              ...qrCode._options.backgroundOptions, // Keep existing options
              gradient: gradient,            // Override with new options
              color: undefined,
          },
      });
  };

  const updateBorderStyles = (style: ExtensionOptions | undefined) => {
      qrCode.deleteExtension();

      if (style !== undefined) {
          qrCode.update({
              backgroundOptions: {
                  ...qrCode._options.backgroundOptions, // Keep existing options
                  round: qrShape === "square" ? 0.2 : 1,
              }
          });
          qrCode.applyExtension(QRBorderPlugin(style));
      } else {
          qrCode.update({
              backgroundOptions: {
                  ...qrCode._options.backgroundOptions, // Keep existing options
                  round: 0
              }
          })
      }

      setBorderStyle(style);
  };

  const onLogoChange = (logoBase64: string | undefined, logoType: QrCodeLogoType, customLogoFileName: string | undefined) => {
      qrCode.update({ image: logoBase64 });
      setLogoType(logoType);
      setCustomLogoFileName(customLogoFileName);
  };

  const handleDownloadQrCode = (extension: FileExtension) => {
      qrCode.download({
          name: `tnlnk_${shortUrl.split('/')[3]}`,
          extension: extension,
      });
  };

  const handleSave = () => {
      if (qrCodeId !== undefined) {
          updateQrCode(
              qrCodeId,
              {
                  qrCodeStyle: qrCode._options,
                  borderStyle: borderStyle,
                  metaData: {
                      // save no-logo, if custom selected but custom image wasn't uploaded
                      logoType: !qrCode._options.image && logoType === "custom" ? "no-logo" : logoType as QrCodeLogoType,
                      customLogoFileName: customLogoFileName
                  }
              },
              searchParams)
              .then(() => showNotification("QR-code saved"));
      }
  };

  const handleRevert = () => {
      getQrCode(qrCodeId, searchParams)
          .then(res => {
              initQrCodeState(res.data);
              showNotification("Reverted to last saved changes")
          })
  };

  const handleClose = () => {
      handleSave();
      setShowModal(false);
      onClose();
  };

  const showNotification = (content: string) => {
      toast.info(content, {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          closeButton: false
      });
  };

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <div style={{ minWidth: "1250px" }}>
              <CustomModalLayout title="QR-code сustomization"
                                 subtitle={
                                     <Box direction="horizontal">
                                         <Tooltip inline content="Short Link">
                                             <Link color="rgb(56, 153, 237)"/>
                                         </Tooltip>
                                         {shortLinkTitle}
                                     </Box>
                                 }
                                 overflowY="none"
                                 maxHeight="fit-content"
                                 primaryButtonText="Save"
                                 primaryButtonOnClick={handleSave}
                                 secondaryButtonText="Close"
                                 secondaryButtonOnClick={handleClose}
                                 onCloseButtonClick={handleClose}
                                 sideActions={<Button priority="secondary"
                                                      size="small"
                                                      prefixIcon={<Revert/>}
                                                      onClick={handleRevert}
                                 >
                                     Revert
                                 </Button>}
                                 content={
                                     <Card>
                                         <Layout>
                                             <Cell span={6} rows={6}>
                                                 <Box direction="vertical" marginTop="SP1">

                                                     <Accordion preExpanded={expanded} onChange={handleAccordionChange}>
                                                         <AccordionItem uuid="style-accordion">
                                                             <AccordionItemHeading>
                                                                 <AccordionItemButton
                                                                     style={getAccordionItemButtonStyle("style-accordion")}
                                                                     onMouseEnter={() => setHoveredItem("style-accordion")}
                                                                     onMouseLeave={() => setHoveredItem(null)}
                                                                 >
                                                                     <Box direction="horizontal"
                                                                          align="space-between"
                                                                          verticalAlign="middle"
                                                                          padding="SP2"
                                                                     >
                                                                         <Heading size="small">Select a style</Heading>
                                                                         <TextButton
                                                                             prefixIcon={isItemExpanded("style-accordion") ?
                                                                                 <ChevronDownLargeSmall/> :
                                                                                 <ChevronRightLargeSmall/>}/>
                                                                     </Box>
                                                                 </AccordionItemButton>
                                                             </AccordionItemHeading>
                                                             <AccordionItemPanel style={accordionItemPanelStyle}>

                                                                 <Box direction="vertical" gap="small"
                                                                      paddingTop="small" paddingBottom="small">
                                                                     <FieldSet legend="Patterns">
                                                                         <QrCodePatternsToggle selected={qrCodePattern}
                                                                                               onClick={updateQrPattern}/>
                                                                     </FieldSet>

                                                                     <FieldSet legend="Corners">
                                                                         <QrCodeCornersToggle selected={cornerPattern}
                                                                                              onClick={updateCornersPattern}/>
                                                                     </FieldSet>

                                                                     <FieldSet legend="Shape">
                                                                         <QrCodeShapeToggle selected={qrShape}
                                                                                            onClick={updateQrShape}/>
                                                                     </FieldSet>
                                                                 </Box>
                                                             </AccordionItemPanel>
                                                         </AccordionItem>

                                                         <AccordionItem uuid="image-accordion">
                                                             <AccordionItemHeading>
                                                                 <AccordionItemButton
                                                                     style={getAccordionItemButtonStyle("image-accordion")}
                                                                     onMouseEnter={() => setHoveredItem("image-accordion")}
                                                                     onMouseLeave={() => setHoveredItem(null)}
                                                                 >
                                                                     <Box direction="horizontal"
                                                                          align="space-between"
                                                                          verticalAlign="middle"
                                                                          padding="SP2"
                                                                     >
                                                                         <Heading size="small">Select a logo</Heading>
                                                                         <TextButton
                                                                             prefixIcon={isItemExpanded("image-accordion") ?
                                                                                 <ChevronDownLargeSmall/> :
                                                                                 <ChevronRightLargeSmall/>}/>
                                                                     </Box>
                                                                 </AccordionItemButton>
                                                             </AccordionItemHeading>
                                                             <AccordionItemPanel style={accordionItemPanelStyle}>
                                                                 <Box paddingTop="small" paddingBottom="small">
                                                                     <QrCodeLogoSelector shortLinkId={shortLinkId}
                                                                                         initLogoType={logoType as QrCodeLogoType}
                                                                                         initLogoBase64={logoBase64}
                                                                                         initCustomLogoFileName={customLogoFileName}
                                                                                         onLogoChange={(imageBase64, logoType, customLogoFileName) => onLogoChange(imageBase64, logoType, customLogoFileName)}/>
                                                                 </Box>
                                                             </AccordionItemPanel>
                                                         </AccordionItem>

                                                         <AccordionItem uuid="color-accordion">
                                                             <AccordionItemHeading>
                                                                 <AccordionItemButton
                                                                     style={getAccordionItemButtonStyle("color-accordion")}
                                                                     onMouseEnter={() => setHoveredItem("color-accordion")}
                                                                     onMouseLeave={() => setHoveredItem(null)}
                                                                 >
                                                                     <Box direction="horizontal"
                                                                          align="space-between"
                                                                          verticalAlign="middle"
                                                                          padding="SP2"
                                                                     >
                                                                         <Heading size="small">Select colors</Heading>
                                                                         <TextButton
                                                                             prefixIcon={isItemExpanded("color-accordion") ?
                                                                                 <ChevronDownLargeSmall/> :
                                                                                 <ChevronRightLargeSmall/>}/>
                                                                     </Box>
                                                                 </AccordionItemButton>
                                                             </AccordionItemHeading>
                                                             <AccordionItemPanel style={accordionItemPanelStyle}>
                                                                 <Box direction="vertical" paddingTop="small"
                                                                      paddingBottom="small" gap="small">
                                                                     <FieldSet legend="Code">
                                                                         <ColorSelect
                                                                             initSingleColor={qrCodeSingleColor}
                                                                             initGradientColors={qrCodeGradient}
                                                                             initColorType={qrColorType as ColorType}
                                                                             initGradientRotation={qrCodeGradientRotation}
                                                                             onSingleColorSelect={(color) => updateDotsOptionsColor(color)}
                                                                             onGradientChange={(gradient) => updateDotsGradient(gradient)}
                                                                         />
                                                                     </FieldSet>

                                                                     <FieldSet legend="Background">
                                                                         <ColorSelect
                                                                             initSingleColor={backgroundSingleColor}
                                                                             initGradientColors={backgroundGradient}
                                                                             initColorType={backgroundColorType as ColorType}
                                                                             initGradientRotation={backgroundGradientRotation}
                                                                             onSingleColorSelect={(color) => updateBackgroundOptionsColor(color)}
                                                                             onGradientChange={(gradient) => updateBackgroundGradient(gradient)}
                                                                         />
                                                                     </FieldSet>
                                                                 </Box>
                                                             </AccordionItemPanel>
                                                         </AccordionItem>

                                                         <AccordionItem uuid="frame-accordion">
                                                             <AccordionItemHeading>
                                                                 <AccordionItemButton
                                                                     style={getAccordionItemButtonStyle("frame-accordion")}
                                                                     onMouseEnter={() => setHoveredItem("frame-accordion")}
                                                                     onMouseLeave={() => setHoveredItem(null)}>
                                                                     <Box direction="horizontal"
                                                                          align="space-between"
                                                                          verticalAlign="middle"
                                                                          padding="SP2">
                                                                         <Heading size="small">Select a frame</Heading>
                                                                         <TextButton
                                                                             prefixIcon={isItemExpanded("frame-accordion") ?
                                                                                 <ChevronDownLargeSmall/> :
                                                                                 <ChevronRightLargeSmall/>}/>
                                                                     </Box>
                                                                 </AccordionItemButton>
                                                             </AccordionItemHeading>
                                                             <AccordionItemPanel style={accordionItemPanelStyle}>
                                                                 <Box direction="vertical" paddingTop="small"
                                                                      paddingBottom="small" gap="tiny">
                                                                     <FieldSet legend="Frame">
                                                                         <QrCodeBorderSelect
                                                                             shape={qrShape as ShapeType}
                                                                             borderStyleExtension={borderStyle}
                                                                             onBorderStyleChange={(style) => updateBorderStyles(style)}
                                                                         />
                                                                     </FieldSet>
                                                                 </Box>
                                                             </AccordionItemPanel>
                                                         </AccordionItem>
                                                     </Accordion>

                                                 </Box>
                                             </Cell>

                                             <Cell span={6} rows={5}>
                                                 <Box direction="vertical" align="center" verticalAlign="middle"
                                                      gap="medium" marginTop="SP2">
                                                     <div ref={qrRef} style={qrCodeContainerStyle}/>

                                                     <DropdownButton options={["png", "jpeg", "svg", "webp"]}
                                                                     onClick={(option) => handleDownloadQrCode(option as FileExtension)}/>
                                                 </Box>
                                             </Cell>

                                             <ToastContainer toastStyle={{width: "185px", height: "75px"}}/>
                                         </Layout>
                                         <br/>
                                     </Card>
                                 }
              />
          </div>
      </Modal>
);
};

export default CustomQrGenerator;
