import React, {useState} from "react";
import {Button, Text, Tooltip} from "@wix/design-system";

import {ViewSource} from "../../models/models";
import {Bounce, toast, ToastContainer} from "react-toastify";

interface ChartLegendProps {
    isQrCodeGenerated: boolean,
    onClick : (viewSource: ViewSource) => void
}

const ChartLegend: React.FC<ChartLegendProps> = (
    {
        isQrCodeGenerated,
        onClick
    }) => {

  const [isLinkClicksSelected, setLinkClicksSelected] = useState(true);
  const [isQrViewsSelected, setIsQrViewsSelected] = useState(true);

  const handleLegendClick = (viewSource: ViewSource) => {
      if (viewSource === ViewSource.Browser) {
          if (!isLinkClicksSelected || isQrViewsSelected) {
              setLinkClicksSelected(!isLinkClicksSelected);
              onClick(viewSource);
          } else {
              showNotification("At least one option should be selected");
          }
      }

      if (viewSource === ViewSource.Qr) {
          if (!isQrViewsSelected || isLinkClicksSelected) {
              setIsQrViewsSelected(!isQrViewsSelected);
              onClick(viewSource);
          } else {
              showNotification("At least one option should be selected");
          }
      }
  };

  const showNotification = (content: string) => {
      toast.info(content, {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          closeButton: false
      });
  };

  return (
      <>
          <Button skin="light"
                  priority="primary"
                  style={{ backgroundColor: isLinkClicksSelected ? 'rgb(230, 240, 255)' : 'rgb(255, 255, 255)' }}
                  onClick={() => handleLegendClick(ViewSource.Browser)}>
              <div style={{
                  width: '30px',
                  height: '15px',
                  backgroundColor: 'rgb(56, 153, 237)',
                  marginRight: '4px'
              }}/>
              <Text>Link Clicks</Text>
          </Button>
          <Tooltip content="Generate QR-code to enable" disabled={isQrCodeGenerated}>
              <Button skin="light"
                      priority="secondary"
                      disabled={!isQrCodeGenerated}
                      style={{ backgroundColor: isQrCodeGenerated ? (isQrViewsSelected ? 'rgb(230, 240, 255)' : 'rgb(255, 255, 255)') : 'rgba(167, 172, 183, 0.5)' }}
                      onClick={() => handleLegendClick(ViewSource.Qr)}>
                  <div style={{
                      width: '30px',
                      height: '15px',
                      backgroundColor: 'rgb(159, 209, 246)',
                      marginRight: '4px'
                  }}/>
                  <Text>QR-code Scans</Text>
              </Button>
          </Tooltip>

          <ToastContainer toastStyle={{ width: "185px", height: "75px" }}/>
      </>
  )
}

export default ChartLegend;
