import {
    MessageModalLayout,
    Modal,
    Text
} from '@wix/design-system';
import React, {useEffect, useState} from 'react';
import {deleteShortLinkByShortUrl} from '../../services/short-link-client';
import {useSearchParams} from "react-router-dom";

interface DeleteShortLinkProps {
    shortUrl: string;
    title: string;
    onDelete: () => void;
    onClose: () => void;
}

const DeleteShortLink: React.FC<DeleteShortLinkProps> = (
    {
         shortUrl,
        title,
        onDelete,
        onClose
    }) => {

  const [linkName, setLinkName] = useState<string>(title);
  const [shortUrlCode, setShortUrlCode] = useState<string | undefined>();
  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
      setShortUrlCode(shortUrl.split('/')[3]);
      setLinkName(title);
  }, [shortUrl, title]);

  const handleDelete = () => {
      deleteShortLinkByShortUrl(shortUrlCode as string, searchParams)
          .then(() => onDelete())
          .finally(() => {
              setShowModal(false);
              onClose();
          });
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <MessageModalLayout theme="destructive"
                              title="Delete this link?"
                              primaryButtonText="Delete"
                              primaryButtonOnClick={handleDelete}
                              secondaryButtonText="Cancel"
                              secondaryButtonOnClick={handleClose}
                              onCloseButtonClick={handleClose}
                              content={
                                        <Text>
                                            You’re about to delete your <b>{linkName}</b> short link.
                                            Your short link will become unpublished and can’t be edited.
                                        </Text>
                              }
          />
      </Modal>
  );
};

export default DeleteShortLink;
