import React, {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {Box, Card, Cell, Layout, Page, WixDesignSystemProvider} from "@wix/design-system";

import AnalyticsHeader from "../components/analytics/AnalyticsHeader";
import BarChartNew from "../components/analytics/BarChartNew";
import DoughnutChart from "../components/analytics/DoughnutChart";
import TableAnalytics from "../components/analytics/TableAnalytics";
import {getViewAnalytics} from "../services/analytics-client";
import moment from "moment/moment";
import {
    AggregationPeriod,
    ShortLinkResponse,
    VendorProductId,
    ViewAnalyticsResponse,
    ViewSource,
} from "../models/models";
import {getShortLinkById} from "../services/short-link-client";

interface AnalyticsProps {
    vendorProductId: VendorProductId;
}

const Analytics: React.FC<AnalyticsProps> = ({ vendorProductId }) => {

  const { id } = useParams<{ id: string }>();
  const linkId = Number(id);
  const [searchParams] = useSearchParams();

  const [aggregationPeriod, setAggregationPeriod] = useState<AggregationPeriod>(AggregationPeriod.Daily);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const [datasetVisibility, setDatasetVisibility] = useState<Map<ViewSource, boolean>>(
      new Map([[ViewSource.Browser, true], [ViewSource.Qr, true]])
  );

  const [shortLink, setShortLink] = useState<ShortLinkResponse | null>(null);
  const [viewAnalytics, setViewAnalytics] = useState<ViewAnalyticsResponse>({
      linkId: 0,
      views: new Map(),
      qrViews: new Map(),
      countryViews: new Map(),
      cityViews: new Map(),
      deviceTypes: new Map(),
      operatingSystems: new Map(),
      browsers: new Map()
  });
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
      const fetchShortLink = async () => {
          try {
              const response = await getShortLinkById(linkId, searchParams);
              setShortLink(response.data);
              setDatasetVisibility(new Map([
                  [ ViewSource.Browser, true ],
                  [ ViewSource.Qr, Boolean(response.data.qrCodeId) ]
              ]));
              setTitle(Boolean(response.data.qrCodeId) ? "Clicks & Scans" : "Clicks");
          } catch (error) {
              console.error("Error fetching short link:", error);
          }
      };
      fetchShortLink();
    }, [linkId, searchParams]);

  useEffect(() => {
      if (!shortLink) return;

      const fetchAnalytics = async () => {
          try {
              const response = await getViewAnalytics({
                  linkId: shortLink.id,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  aggregationPeriod,
                  viewSources: Array.from(datasetVisibility.entries())
                      .filter(([_, value]) => value)
                      .map(([key]) => key)
              }, searchParams);
              setViewAnalytics(response.data);
          } catch (error) {
              console.error("Error fetching analytics:", error);
          }
      };
      fetchAnalytics();
    }, [shortLink, startDate, endDate, aggregationPeriod, datasetVisibility, searchParams]);

  const handleAggregationPeriodChange = (newPeriod: AggregationPeriod, start: Date, end: Date) => {
      setAggregationPeriod(newPeriod);
      setStartDate(start);
      setEndDate(end);
  };

  const handleDateRangeChange = (start: Date, end: Date) => {
      setStartDate(start);
      setEndDate(end);
  };

  const handleLegendClick = (visibility: Map<ViewSource, boolean>) => {
      setDatasetVisibility(visibility);
      let title = "";
      if (visibility.get(ViewSource.Browser) && visibility.get(ViewSource.Qr)) {
          title = "Clicks & Scans";
      } else if (visibility.get(ViewSource.Browser)) {
          title = "Clicks";
      } else if (visibility.get(ViewSource.Qr)) {
          title = "Scans";
      }
      setTitle(title);
  };

  if (!shortLink) {
      return <div>Error loading short link data</div>;
  }

  return (
      <WixDesignSystemProvider>
          <Page height="100vh">
              <Page.Header title="TinyLinks Analytics"
                           minimized={true}
                           actionsBar={
                                <AnalyticsHeader isQrCodeGenerated={Boolean(shortLink.qrCodeId)}
                                                 aggregationPeriod={aggregationPeriod}
                                                 createdDate={shortLink.createdDate}
                                                 vendorProductId={vendorProductId}
                                                 onLegendClick={handleLegendClick}
                                                 onAggregationPeriodChange={handleAggregationPeriodChange}
                                                 onDateRangeChange={handleDateRangeChange}
                                />
                           }
              />
              <Page.Content>
                  <Layout cols={2}>
                      <Cell span={2}>
                          <Box height="40vh">
                              <Card showShadow stretchVertically>
                                  <Card.Header title={`${title} over time`} />
                                  <Card.Divider />
                                  <Card.Content>
                                      <BarChartNew views={viewAnalytics.views}
                                                   qrViews={viewAnalytics.qrViews}
                                                   aggregationPeriod={aggregationPeriod}
                                                   datasetVisibility={datasetVisibility} />
                                  </Card.Content>
                              </Card>
                          </Box>
                      </Cell>
                      <Cell span={1}>
                          <Box height="40vh">
                              <Card showShadow stretchVertically>
                                  <Card.Header title={`${title} by device type`} />
                                  <Card.Divider />
                                  <Card.Content>
                                      <DoughnutChart title={title} dataset={viewAnalytics.deviceTypes} />
                                  </Card.Content>
                              </Card>
                          </Box>
                      </Cell>
                      <Cell span={1}>
                          <Box height="40vh">
                              <Card showShadow stretchVertically>
                                  <TableAnalytics title={title}
                                                  countryData={viewAnalytics.countryViews}
                                                  cityData={viewAnalytics.cityViews} />
                              </Card>
                          </Box>
                      </Cell>
                  </Layout>
              </Page.Content>
          </Page>
      </WixDesignSystemProvider>
  );
};

export default Analytics;
