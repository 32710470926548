import React from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import {Box} from "@wix/design-system";

import { ViewsPercentage } from '../../models/models';
import DoughnutChartLegend from "./DoughnutChartLegend";

interface DoughnutChartProps {
    title: string,
    dataset: Map<string, ViewsPercentage>
}

const DoughnutChart: React.FC<DoughnutChartProps> = (
    {
        title,
        dataset = new Map<string, ViewsPercentage>(),
    }) => {

  const sortedEntries = Object.entries(dataset).sort(([, a], [, b]) => b.viewCount - a.viewCount);

  const labels = sortedEntries.map(([key]) => key) || [];
  const data = sortedEntries.map(([, value]) => value.viewCount as number);
  const colors = sortedEntries.map((_, index) => `hsl(${(190 + index * 36) % 360}, 58%, 50%)`);

  // Memoize the transformed data
  const chartData: ChartData<'doughnut', number[], string> = {
      labels: labels || ["No data"],
      datasets: [
          {
              data: data.length > 0 ? data : [1],
              backgroundColor: colors.length > 0 ? colors : ["#d3d3d3"],
              borderWidth: 0,
              hoverOffset: data.length > 0 ? 10 : 0
          },
      ],
  };

  const options: ChartOptions<'doughnut'> = {
      responsive: true,
      maintainAspectRatio: true,
      cutout: '80%',
      plugins: {
          legend: {
              display: false
          },
          tooltip: {
              enabled: false,
          },
      },
      animation: {
          animateScale: true, // Animates scaling on hover
      },
      hover: {
          mode: "point",
          intersect: true,
      }
  };

  // Custom plugin to draw text in the center
  const centerTextPlugin = (title: string): Plugin<'doughnut'> => ({
      id: 'centerText',
      afterDraw: (chart) => {
          const { ctx, chartArea } = chart;

          const { width, height } = chartArea;
          const xCenter = width / 2 + chartArea.left;
          const yCenter = height / 2 + chartArea.top;

          const total = data.length > 0 ? chart.data.datasets[0].data.reduce((acc, num) => acc + num, 0) : 0;

          ctx.save();
          ctx.font = "bold 20px Madefor, 'Helvetica Neue', Helvetica, Arial, meiryo, 'hiragino kaku gothic pro', sans-serif";
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          const activeElements = chart.getActiveElements();
          if (activeElements.length > 0 && data.length > 0) {
              const activeElement = activeElements[0];
              const dataset = chart.data.datasets[activeElement.datasetIndex];
              const value = dataset.data[activeElement.index];

              ctx.fillText(String(value), xCenter, yCenter - 10);
          } else {
              ctx.fillText(String(total), xCenter, yCenter - 10);
          }

          ctx.fillText(title, xCenter, yCenter + 10);
          ctx.restore();
      }
  });

  const toDeviceData = () => {
      return labels.map((label, index) => ({
          type: label,
          count: data[index],
          color: colors[index]
      }));
  };

  return (
      <Box direction="horizontal" height="calc(40vh - 110px)" gap="SP10" verticalAlign="top">
          <Doughnut data={chartData} options={options} plugins={[centerTextPlugin(title)]}/>
          <Box verticalAlign="top">
              <DoughnutChartLegend data={toDeviceData()}/>
          </Box>
      </Box>
  );
};

export default DoughnutChart;
