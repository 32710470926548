import React, {useState, useEffect, SyntheticEvent} from "react";
import {Box, Pagination, SegmentedToggle, Table, TableToolbar} from "@wix/design-system";
import { ViewsPercentage } from "../../models/models";
import {TableColumn} from "@wix/design-system/dist/types/Table";

type DataType = "Country" | "City";

interface TableData {
    index: number;
    name: string;
    views: number;
    percentage: number;
}

interface PaginationChangeParams {
    page: number;
    event: SyntheticEvent;
}

interface TableAnalyticsProps {
    title: string,
    countryData: Map<string, ViewsPercentage>;
    cityData: Map<string, ViewsPercentage>;
}

const TableAnalytics: React.FC<TableAnalyticsProps> = (
    {
        title,
        countryData = new Map<string, ViewsPercentage>(),
        cityData = new Map<string, ViewsPercentage>()
    }) => {

  const TABLE_ROWS_QUANTITY: number = 5;

  const [selectedDataType, setSelectedDataType] = useState<DataType>("Country");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableRecords, setTableRecords] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const transformData = (data: Map<string, ViewsPercentage>): TableData[] =>
      Object.entries(data)
          .sort(([, a], [, b]) => b.viewCount - a.viewCount)
          .map(([key, value], index) => ({
              index: index + 1,
              name: key,
              views: value.viewCount,
              percentage: value.percentage
      }));

  useEffect(() => {
      const data = selectedDataType === "Country" ? countryData : cityData;
      const tableData = transformData(data);
      setTableData(tableData);
      setTableRecords(tableData.slice(0, TABLE_ROWS_QUANTITY));
      setPageCount(Math.ceil(tableData.length / TABLE_ROWS_QUANTITY));
    }, [selectedDataType, countryData, cityData]);

  const columns: TableColumn<TableData>[] = [
      {
          title: "#",
          width: "10%",
          render: (row: TableData) => row.index
      },
      {
          title: selectedDataType,
          width: "55%",
          render: (row: TableData) => selectedDataType === "Country" ? row.name : row.name.split("/")[3]
      },
      {
          title: title,
          width: "20%",
          align: "center",
          render: (row: TableData) => row.views
      },
      {
          title: "%",
          width: "15%",
          align: "center",
          render: (row: TableData) => row.percentage
      }
  ];

  const handleDataTypeChange = (dataType: DataType) => {
      setSelectedDataType(dataType);
      changePage(1);
  };

  const handleTablePageChange = ({ page, event }: PaginationChangeParams) => {
      event.preventDefault();
      changePage(page);
  };

  const changePage = (page: number) => {
      setSelectedPage(page);
      setTableRecords(tableData.slice((page - 1) * TABLE_ROWS_QUANTITY, (page - 1) * TABLE_ROWS_QUANTITY + TABLE_ROWS_QUANTITY));
  };

  return (
      <Box direction="vertical" align="center">
          <Table skin="standard" data={tableRecords} columns={columns}>
              <TableToolbar>
                  <TableToolbar.Title>{title} by location</TableToolbar.Title>

                  <TableToolbar.Item>
                      <SegmentedToggle defaultSelected={selectedDataType}
                                       size="small"
                                       onClick={(_, dataType) => handleDataTypeChange(dataType as DataType)}>
                          <SegmentedToggle.Button value="Country" style={{ minWidth: "100px" }}>Countries</SegmentedToggle.Button>
                          <SegmentedToggle.Button value="City" style={{ minWidth: "100px" }}>Cities</SegmentedToggle.Button>
                      </SegmentedToggle>
                  </TableToolbar.Item>
              </TableToolbar>

              <Table.Content/>

              { tableData.length === 0 && (
                  <Table.EmptyState title="No data for selected period" />
              )}
          </Table>

          { tableData.length > TABLE_ROWS_QUANTITY && (
              <Pagination currentPage={selectedPage}
                          totalPages={pageCount}
                          onChange={handleTablePageChange} />
          )}
      </Box>
  );
};

export default TableAnalytics;
