import axios from "axios";
import {InstanceInfoResponse} from "../models/models";

export const getInstanceInfo = async (queryParams: URLSearchParams) => {
    try {
        return await axios.get<InstanceInfoResponse>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/instance`,
            { params:
                    {
                        instance: queryParams.get("instance")
                    }
            }
        );
    } catch (e) {
        throw e;
    }
}
