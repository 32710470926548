import {
    MessageModalLayout,
    Modal,
    Text
} from '@wix/design-system';
import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {deleteDomain} from "../../services/domain-client";

interface DeleteDomainModalProps {
    domainId: number,
    domainName: string,
    onDelete: () => void,
    onClose: () => void
}

const DeleteDomainModal: React.FC<DeleteDomainModalProps> = (
    {
        domainId,
        domainName,
        onDelete,
        onClose
    }) => {

  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const handleDelete = (domainId: number) => {
      deleteDomain(domainId, searchParams)
          .then(() => onDelete())
          .finally(() => {
              setShowModal(false);
              onClose();
          });
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <MessageModalLayout theme={'destructive'}
                              title="Delete custom domain?"
                              primaryButtonText="Delete"
                              primaryButtonOnClick={ () => handleDelete(domainId) }
                              secondaryButtonText="Cancel"
                              secondaryButtonOnClick={handleClose}
                              onCloseButtonClick={handleClose}
                              content={
                                  <Text>
                                      You’re about to delete your custom domain <b>{domainName}</b>.
                                      TODO!!!!
                                  </Text>
                              }
          />
      </Modal>
  );
};

export default DeleteDomainModal;
