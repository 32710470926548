import {AggregationPeriod, VendorProductId} from "../../models/models";

export const getMinDate = (createdDate: Date, aggregationPeriod: AggregationPeriod, vendorProductId: VendorProductId) : Date => {
  const minDate = new Date();

  console.log("GetMinDate");

  if (vendorProductId === VendorProductId.basic) {
      console.log("Basic")
      minDate.setDate(minDate.getDate() - 29);
  } else if (vendorProductId === VendorProductId.business) {
      console.log("Business")
      if (aggregationPeriod === AggregationPeriod.Monthly) {
          console.log("--Monthly")
          minDate.setMonth(minDate.getMonth() - 2);
          minDate.setDate(1);
      } else {
          console.log("--Daily")
          minDate.setDate(minDate.getDate() - 89);
      }
  } else if (vendorProductId === VendorProductId.ultimate) {
      console.log("Ultimate");
      if (aggregationPeriod === AggregationPeriod.Monthly) {
          console.log("--Monthly")
          minDate.setMonth(minDate.getMonth() - 11);
          minDate.setDate(1);
      } else {
          console.log("--Daily")
          minDate.setDate(minDate.getDate() - 364);
      }
  }

  console.log(createdDate + " < " + minDate);
  if (createdDate < minDate) {
      console.log("true");
      return minDate;
  }

  console.log("false");
  return createdDate;
};

export const getStartDate = (createdDate: Date, aggregationPeriod: AggregationPeriod) : Date => {
  const initStartDate: Date = new Date();

  console.log("Start date");

  switch (aggregationPeriod) {
      case AggregationPeriod.Daily:
          console.log("Daily");
          initStartDate.setDate(initStartDate.getDate() - 29);
          break;
      case AggregationPeriod.Monthly:
          console.log("Monthly");
          initStartDate.setMonth(initStartDate.getMonth() - 2)
          initStartDate.setDate(1);
          break;
  }

  console.log(createdDate + "<" + initStartDate);
  if (createdDate < initStartDate) {
      console.log("true");
      return initStartDate;
  }

  console.log("false");
  return createdDate;
};
