import * as yup from "yup";

const domainNameRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,8}$/;

export const domainNameSchema = yup.object().shape({
    domainName: yup.string()
        .trim("domain name can't include leading and trailing spaces")
        .matches(domainNameRegex, "Please enter a valid domain name")
        .min(4, "domain name should include al least 4 characters")
        .max(36, "domain name should be no more than 36 characters")
        .required("domain name required")
});
