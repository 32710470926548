import React, {ChangeEvent, useEffect, useState} from "react";
import {ShapeType} from "qr-code-styling/lib/types";
import {Box, ColorInput, FieldSet, Input, Thumbnail} from "@wix/design-system";
import { Dismiss } from '@wix/wix-ui-icons-common';
import {
    borderStyles,
    getBorderColor,
    getBorderStyle,
    getBorderText,
    getBorderTextColor,
} from "./QrBorderStyles";
import {ExtensionOptions} from "qr-border-plugin";
import QrBorderSquareBold from "../icon/QrBorderSquareBold";
import QrBorderSquare from "../icon/QrBorderSquare";
import QrBorderSquareText from "../icon/QrBorderSquareText";
import QrBorderCircle from "../icon/QrBorderCircle";
import QrBorderCircleBold from "../icon/QrBorderCircleBold";
import QrBorderCircleText from "../icon/QrBorderCircleText";

export type BorderType = "no-border" | "text" | "no-text";
export type BorderStyle = "no-border" | "simple-border" | "bold-border" | "text-border";

interface FrameColorSelectProps {
    shape: ShapeType,
    borderStyleExtension: ExtensionOptions | undefined,
    onBorderStyleChange: (newBorderStyle: ExtensionOptions | undefined) => void
}

const QrCodeBorderSelect: React.FC<FrameColorSelectProps> = (
    {
        shape,
        borderStyleExtension,
        onBorderStyleChange
    }) => {

  const [borderStyle, setBorderStyle] = useState<BorderStyle>("no-border");
  const [borderType, setBorderType] = useState<BorderType>("no-border");

  const [borderColor, setBorderColor] = useState<string>("#000000");
  const [borderText, setBorderText] = useState<string>("SCAN ME");
  const [textColor, setTextColor] = useState<string>("#FFFFFF");

  useEffect(() => {
      const borderStyle = getBorderStyle(borderStyleExtension);
      const borderColor = getBorderColor(borderStyleExtension);
      const borderText = getBorderText(borderStyleExtension);
      const textColor = getBorderTextColor(borderStyleExtension);

      handleChangeBorder(borderStyle, borderColor, borderText, textColor);
  }, [borderStyleExtension, shape]);

  const handleBorderTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      handleChangeBorder(borderStyle, borderColor, text, textColor);
  };

  const handleChangeTextColor = (textColor: string) => {
      handleChangeBorder(borderStyle, borderColor, borderText, textColor);
  };

  const handleChangeBorderColor = (color: string) => {
      handleChangeBorder(borderStyle, color, borderText, textColor);
  };

  const handleChangeBorder = (borderStyle: BorderStyle, color: string, text: string, textColor: string) => {
      setBorderStyle(borderStyle);
      if (borderStyle === "no-border") {
          setBorderType("no-border");
          onBorderStyleChange(undefined);
      } else {
          const newBorderStyle = borderStyles[borderStyle].options;
          newBorderStyle.color = color;
          newBorderStyle.borderInner.color = color;
          newBorderStyle.borderOuter.color = color;
          setBorderColor(color);

          if (shape === "circle") {
              newBorderStyle.round = 1;
          } else if (shape === "square") {
              newBorderStyle.round = 0.2;
          }

          let newBorderType: BorderType = "no-text";

          if (borderStyles[borderStyle].isText) {
              newBorderType = "text";
              newBorderStyle.decorations["bottom"].value = text;
              newBorderStyle.decorations["bottom"].style = "font: 20px sans-serif; fill: " + textColor + ";";

              setBorderText(text);
              setTextColor(textColor);
          }

          setBorderType(newBorderType);
          onBorderStyleChange(newBorderStyle);
      }
  };

  return (
      <Box direction="vertical" gap="medium">
          <Box direction="horizontal" gap="SP2">
              <Thumbnail size="medium"
                         selected={"no-border" === borderStyle}
                         onClick={() => handleChangeBorder("no-border" as BorderStyle, borderColor, borderText, textColor)}
                         image={<Dismiss size="100%"/>}
                         contentAlignment="top"
                         textPosition="outside"
                         width="15%"
                         height="90%"
              />
              <Thumbnail size="medium"
                         selected={"simple-border" === borderStyle}
                         onClick={() => handleChangeBorder("simple-border" as BorderStyle, borderColor, borderText, textColor)}
                         image={ shape === "circle" ? <QrBorderCircle size="100%"/> : <QrBorderSquare size="100%"/> }
                         contentAlignment="top"
                         textPosition="outside"
                         width="15%"
                         height="90%"
              />
              <Thumbnail size="medium"
                         selected={"bold-border" === borderStyle}
                         onClick={() => handleChangeBorder("bold-border" as BorderStyle, borderColor, borderText, textColor)}
                         image={ shape === "circle" ? <QrBorderCircleBold size="100%"/> : <QrBorderSquareBold size="100%"/> }
                         contentAlignment="top"
                         textPosition="outside"
                         width="15%"
                         height="90%"
              />
              <Thumbnail size="medium"
                         selected={"text-border" === borderStyle}
                         onClick={() => handleChangeBorder("text-border" as BorderStyle, borderColor, borderText, textColor)}
                         image={ shape === "circle" ? <QrBorderCircleText size="100%"/> : <QrBorderSquareText size="100%"/> }
                         contentAlignment="top"
                         textPosition="outside"
                         width="15%"
                         height="90%"
              />
          </Box>

          { borderType === "no-text" &&
              <Box direction="horizontal" gap="medium">
                  <FieldSet legend="Frame color">
                      <ColorInput value={borderColor}
                                  popoverPlacement="top-end"
                                  popoverProps={{ animate: true }}
                                  onConfirm={ (color) => handleChangeBorderColor(color as string) } />

                  </FieldSet>
              </Box>
          }

          { borderType === "text" &&
              <Box direction="vertical" gap="small">
                  <Box direction="horizontal" gap="medium">
                      <FieldSet legend="Frame color">
                          <ColorInput value={borderColor}
                                      popoverPlacement="top-end"
                                      popoverProps={{ animate: true }}
                                      onConfirm={ (color) => handleChangeBorderColor(color as string) } />
                      </FieldSet>

                      <FieldSet legend="Text color">
                          <ColorInput value={textColor}
                                      popoverPlacement="top-end"
                                      popoverProps={{ animate: true }}
                                      onConfirm={ (color) => handleChangeTextColor(color as string) } />
                      </FieldSet>
                  </Box>

                  <FieldSet legend="Frame text">
                      <Input size="medium"
                             value={borderText}
                             maxLength={shape === "square" ? 38 : 53}
                             onChange={handleBorderTextChange} />
                  </FieldSet>
              </Box>
          }
      </Box>
  );
};

export default QrCodeBorderSelect;
