import axios from 'axios';
import {QrCodeRequest, QrCodeResponse} from "../models/models";

export const generateQrCode = async (shortLinkId: number, queryParams: URLSearchParams) => {
    try {
        return await axios.post<QrCodeResponse>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/qr`,
            {shortLinkId: shortLinkId},
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
};

export const getQrCode = async (qrCodeId: number, queryParams: URLSearchParams) => {
    try {
        return await axios.get<QrCodeResponse>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/qr/${qrCodeId}`,
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
};

export const updateQrCode = async (qrCodeId: number, qrCode: QrCodeRequest, queryParams: URLSearchParams) => {
    try {
        return await axios.patch<QrCodeResponse>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/qr/${qrCodeId}`,
            qrCode,
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
};
