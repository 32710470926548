import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CustomModalLayout,
    Modal,
    Table,
    TableActionCell,
    TextButton
} from "@wix/design-system";
import {useSearchParams} from "react-router-dom";
import {Add, Delete, Refresh} from "@wix/wix-ui-icons-common";
import {DomainResponse, VerificationStatus} from "../../models/models";
import DomainStatusBadge from "./DomainStatusBadge";
import {getDomains} from "../../services/domain-client";

interface DomainManagementModalProps {
    openAddDomainModal: () => void,
    openRestartDomainVerificationModal: (domainId: number, domainName: string) => void,
    openDeleteModal: (domainId: number, domainName: string) => void,
    onClose: () => void
}

const DomainManagementModal: React.FC<DomainManagementModalProps> = (
    {
        openAddDomainModal,
        openRestartDomainVerificationModal,
        openDeleteModal,
        onClose
    }) => {
    
  const [domainsResponse, setDomainsResponse] = useState<DomainResponse[]>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
      getDomains(searchParams).then(
          res => {
              setDomainsResponse(res.data);
          })
  }, []);

  const columns = [
      {
          title: "domain name",
          render: (row: any) => row.domainName,
          width: '25%'
      },
      {
          title: "Created at",
          render: (row: any) => new Date(row.createdDate).toLocaleString(),
          width: '25%'
      },
      {
          title: "Verification status",
          render: (row: any) => row.verificationStatusBadge,
          width: '25%'
      },
      {
          title: "",
          render: (row: any) => (
              <TableActionCell secondaryActions={secondaryActions(row.id, row.domainName, row.verificationStatus)}
                               alwaysShowSecondaryActions={true}
                               numOfVisibleSecondaryActions={2}
              />
          )
      }
  ];

  const secondaryActions = (domainId: number,
                            domainName: string,
                            verificationStatus: VerificationStatus) : ({
      text: string;
      icon: React.JSX.Element;
      visibility: string;
      skin: 'dark' | 'destructive' | undefined;
      onClick: () => void;
                            })[]  => {

      if (verificationStatus === VerificationStatus.Failed) {
          return [
              {
                  text: "Rerun verification",
                  icon: <Refresh/>,
                  visibility: 'always',
                  skin: 'dark',
                  onClick: () => {
                      openRestartDomainVerificationModal(domainId, domainName)
                  }
              },
              {
                  text: "Delete",
                  icon: <Delete/>,
                  visibility: 'always',
                  skin: 'destructive',
                  onClick: () => {
                      openDeleteModal(domainId, domainName)
                  }
              }
          ];
      } else {
          return [
              {
                  text: "Delete",
                  icon: <Delete/>,
                  visibility: 'always',
                  skin: 'destructive',
                  onClick: () => {
                      openDeleteModal(domainId, domainName)
                  }
              }
          ];
      }
  };

  const domainTableItems = domainsResponse.map(domain => ({
      id: domain.id,
      domainName: domain.domainName,
      verificationStatus: domain.verificationStatus,
      verificationStatusBadge: <DomainStatusBadge>{domain.verificationStatus}</DomainStatusBadge>,
      createdDate: domain.createdDate
  }));

  return (
      <Modal isOpen
             onRequestClose={onClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout title="Custom domains"
                             onCloseButtonClick={onClose}
                             content={
                                <Card>
                                    <Box direction="vertical" align="center">
                                        <Table data={domainTableItems} columns={columns}>
                                            <Table.Content titleBarVisible={true} />

                                            { domainTableItems.length === 0 && (
                                                <Table.EmptyState title="Your domain list is empty"
                                                                  subtitle="Get started by adding a custom domain to create short links with your unique URL"/>
                                            )}
                                        </Table>
                                        <TextButton size="small"
                                                    prefixIcon={<Add />}
                                                    skin="standard"
                                                    priority="secondary"
                                                    onClick={() => {
                                                        openAddDomainModal();
                                                    }}
                                        >
                                            Add your custom domain
                                        </TextButton>
                                        <p/>
                                    </Box>
                                </Card>
                             }
          />
      </Modal>
  );

}

export default DomainManagementModal;
