import React from 'react';
import {Box, Text} from "@wix/design-system";

interface DeviceData {
    type: string;
    count: number;
    color: string;
}

interface DoughnutChartLegendProps {
    data: DeviceData[]
}

const DoughnutChartLegend: React.FC<DoughnutChartLegendProps> = ({data}) => {
  return (
      <table>
          <tbody>
              {data.map((device) => (
                  <tr key={device.type}>
                      <td style={{ paddingRight: "4em" }}>
                          <Box direction="horizontal" verticalAlign="middle" gap="SP1">
                              <div style={{
                                    width: 15,
                                    height: 15,
                                    backgroundColor: device.color,
                                    borderRadius: "50%"
                              }}/>
                              <Text>{device.type}</Text>
                          </Box>
                      </td>
                      <td style={{ textAlign: "right" }}>
                          <Text>{device.count}</Text>
                      </td>
                  </tr>
              ))}
          </tbody>
      </table>
  );
};

export default DoughnutChartLegend;
