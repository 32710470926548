import {BrowserRouter, Route, Routes} from "react-router-dom";

import {VendorProductId} from "./models/models";
import TinyLinks from "./pages/TinyLinks";
import Analytics from "./pages/Analytics";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/"
                     element={<TinyLinks />}
              />
              <Route path="/analytics/:id"
                     element={<Analytics vendorProductId={VendorProductId.business} />}
              />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
